/* Page Setup + Mixins */

$breakWide: 1648px;
$breakTabletLndscp: 1024px;
$breakTablet: 768px;
$breakSmall: 650px;

:root {
  --gutter: 16px;
  --pageMarginTotal: 16px;
  --pageMargin: calc(var(--pageMarginTotal) - (var(--gutter) / 2));
  --easingOut: cubic-bezier(0.16, 1, 0.3, 1);
  --easing: cubic-bezier(0.87, 0, 0.13, 1);
  --headerHeight: 60px;
  --id-c-green-100: #3a423a;
  --id-c-green-20: #edefea;
  --id-c-grey-100: #565656;
  --id-c-grey-80: #767676;
  --id-c-grey-60: #959595;
  --id-c-grey-40: #b8b8b8;
  --id-c-grey-20: #eceae9;
  --id-c-white: #ffffff;
  --id-c-black: #000000;
  --id-c-brown: #3b3735;
  --id-c-yellow-signal: #f4bf35;
  --id-c-green-signal: #09b76e;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-40: 40px;
  --spacing-60: 60px;
  --spacing-80: 80px;
  --spacing-120: 120px;

  @media all and (max-width: $breakSmall) {
    --gutter: 8px;
    --headerHeight: 52px;
  }
}

@import "reset";
@import "grid";

@font-face {
  font-family: "untitledsans";
  src: url("/assets/fonts/untitled-sans-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

/* Type */

@mixin type-heading {
  font-size: 60px;
  line-height: 64px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.04em;
  margin-left: -0.1em;

  @media all and (max-width: $breakSmall) {
    font-size: 36px;
    line-height: 40px;
  }
}

@mixin type-body {
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.04em;
  font-feature-settings: "tnum" on, "lnum" on;

  @media all and (max-width: $breakSmall) {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
  }
}

// .text-row {
//   margin-left: -0.1em;
// }

.text-row.work-detail-intro {
  margin-left: -0.1em;

  p {
    max-width: 100%;
  }
}

.text-row.journal-detail-intro {
  margin-left: -0.1em;
}

@mixin type-detail {
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.03em;
}

h1,
.type-heading {
  @include type-heading;
}

.type-heading {
  display: block;
}

.type-body {
  @include type-body;
}

.type-detail {
  @include type-detail;

  h3 {
    font-size: inherit;
    font-weight: 400;
  }
}

h1 {
  color: var(--id-c-green-100);
}

h3 {
  color: var(--id-c-black);
}

/* Colours */

.bg-green-100 {
  background: var(--id-c-green-100);
}

.bg-green-20 {
  background: var(--id-c-green-20);
}

.bg-grey-100 {
  background: var(--id-c-grey-100);
}

.bg-grey-80 {
  background: var(--id-c-grey-80);
}

.bg-grey-60 {
  background: var(--id-c-grey-60);
}

.bg-grey-40 {
  background: var(--id-c-grey-40);
}

.bg-grey-20 {
  background: var(--id-c-grey-20);
}

.bg-white {
  background: var(--id-c-white);
}

.bg-black {
  background: var(--id-c-black);
}

.bg-brown {
  background: var(--id-c-brown);
}

.bg-yellow-signal {
  background: var(--id-c-yellow-signal);
}

.bg-green-signal {
  background: var(--id-c-green-signal);
}

.color-green-100 {
  color: var(--id-c-green-100);
}

.color-green-20 {
  color: var(--id-c-green-20);
}

.color-grey-100 {
  color: var(--id-c-grey-100);
}

.color-grey-80 {
  color: var(--id-c-grey-80);
}

.color-grey-60 {
  color: var(--id-c-grey-60);
}

.color-grey-40 {
  color: var(--id-c-grey-40);
}

.color-grey-20 {
  color: var(--id-c-grey-20);
}

.color-white {
  color: var(--id-c-white);
}

.color-black {
  color: var(--id-c-black);
}

.color-brown {
  color: var(--id-c-brown);
}

.color-yellow-signal {
  color: var(--id-c-yellow-signal);
}

.color-green-signal {
  color: var(--id-c-green-signal);
}

.spacer-8 {
  height: var(--spacing-8);
}

.spacer-12 {
  height: var(--spacing-12);
}

.spacer-16 {
  height: var(--spacing-16);
}

.spacer-20 {
  height: var(--spacing-20);
}

.spacer-40 {
  height: var(--spacing-40);
}

.spacer-120 {
  height: var(--spacing-120);
}

/* Grid Overlay */

.grid-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .column-overlay {
    background: var(--id-c-yellow-signal);
    opacity: 0.1;
    height: 100vh;
  }
}

/* General Styles */

.transition-fade {
  transition: 0.2s var(--easing);
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

::selection {
  background: var(--id-c-yellow-signal);
  color: var(--id-c-white);
}

body {
  @include type-body;
  font-family: "untitledsans", sans-serif;
  color: var(--id-c-grey-80);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: var(--headerHeight);
}

main {
  opacity: 0;
  animation: fadeIn 0.4s forwards ease;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

a {
  text-decoration: none;
  color: currentColor;
}

p:not(.type-detail) a,
span.type-heading a,
span.non-link {
  color: var(--id-c-grey-100);
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0px;
    left: 0;
    background: var(--id-c-grey-20);
    transition: background 0.2s var(--easing);
    z-index: -1;
  }

  &:hover {
    &:after {
      background: var(--id-c-yellow-signal);
    }
  }
}

span.non-link:hover {
  &:after {
    background: var(--id-c-grey-20);
  }
}

p.type-detail a {
  color: var(--id-c-grey-100);

  &:hover {
    color: var(--id-c-grey-80);
  }
}

header {
  background: white;
  padding: var(--spacing-16) 0;
  color: var(--id-c-green-100);
  border-bottom: 1px solid var(--id-c-grey-20);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.4s var(--easingOut);
  z-index: 5000;

  &.hide {
    transform: translateY(-100%);
  }

  >.columns {
    align-items: center;
  }

  .header-logo {
    display: block;
    font-size: 24px;
    line-height: 28px;
    height: 28px;
    color: var(--id-c-green-100);
    transition: color 0.2s var(--easing);

    &:hover {
      color: var(--id-c-grey-80);
    }

    @media all and (max-width: $breakSmall) {
      font-size: 16px;
      line-height: 20px;
      height: 20px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 80px;

      @media all and (max-width: $breakSmall) {
        max-width: 61px;
      }
    }
  }

  .header-right {
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      li {
        margin-left: var(--spacing-20);
        transition: color 0.2s var(--easing);

        &.active,
        &:hover {
          color: var(--id-c-grey-80);
        }
      }
    }
  }
}

.availability {
  padding-bottom: var(--spacing-120);

  @media all and (max-width: $breakSmall) {
    padding-bottom: var(--spacing-40);
  }
}

main {
  padding-top: var(--spacing-16);
}

.intro {
  padding-bottom: var(--spacing-16);

  @media all and (max-width: $breakSmall) {
    padding-bottom: var(--spacing-60);
  }
}

.intro+.work-detail-intro,
.intro+.journal-detail-intro {
  padding-top: var(--spacing-40);
}

.intro-illustration-col {
  position: relative;

  @media all and (max-width: $breakSmall) {
    margin-top: 100px;
  }
}

.intro-illustration {
  position: absolute;
  bottom: 0;
  right: calc(var(--gutter) / 2);

  @media all and (max-width: $breakSmall) {
    position: static;
  }

  img {
    width: 100%;
    max-width: 200px;

    @media all and (max-width: $breakSmall) {
      max-width: 180px;
    }
  }
}

.media {
  height: auto;
  overflow: hidden;
  border-radius: 8px;
  transition: border-radius 0.3s ease;
  position: relative;

  img,
  video {
    width: 100%;
    display: block;
  }

  &.no-rounded-corners {
    border-radius: 0;
  }
}

a:hover .media:not(.no-rounded-corners) {
  border-radius: 4px;
}

.video-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
  cursor: pointer;

  .play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background: rgba(58, 66, 58, 0.8);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    transition: background 0.2s var(--easing);

    @media all and (max-width: $breakSmall) {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
  }

  &:hover {
    .play {
      background: rgba(244, 191, 53, 0.8);
    }
  }
}

.media.playing .video-controls {
  .play {
    animation: videoControlPause 0.3s forwards ease;
  }
}

.media.paused .video-controls {
  .play {
    animation: videoControlPlay 0.2s forwards ease;
  }
}

@keyframes videoControlPause {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes videoControlPlay {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.2);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.work-title,
.news-title {
  display: block;
  color: var(--id-c-green-100);
  margin-top: var(--spacing-12);

  @media all and (max-width: $breakSmall) {
    margin-top: var(--spacing-12);
  }
}

.news-title {
  @media all and (max-width: $breakSmall) {
    @include type-detail;
    margin-top: var(--spacing-8);
  }
}

.all-work-listing {
  margin-bottom: calc(var(--spacing-80) * -1);

  @media all and (max-width: $breakSmall) {
    margin-bottom: calc(var(--spacing-40) * -1);
  }

  .col {
    margin-bottom: var(--spacing-80);

    @media all and (max-width: $breakSmall) {
      margin-bottom: var(--spacing-40);
    }
  }
}

.case-row,
.news-row {
  margin-bottom: var(--spacing-80);

  @media all and (max-width: $breakSmall) {
    margin-bottom: var(--spacing-40);
  }

  >.columns>.col:not(:last-child) {
    @media all and (max-width: $breakSmall) {
      margin-bottom: var(--spacing-40);
    }
  }
}

.news-row {
  @media all and (max-width: $breakSmall) {
    margin-bottom: 0;
  }
}

.news-content {
  @media all and (max-width: $breakSmall) {
    @include type-detail;
  }
}

.designer-tags {
  margin-top: var(--spacing-8);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;

  @media all and (max-width: $breakSmall) {
    margin-top: var(--spacing-4);
  }
}

.designer-tag {
  background: var(--id-c-green-20);
  color: var(--id-c-grey-80);
  padding: 4px 12px;
  display: inline-flex;
  border-radius: 24px;

  // &:hover {
  //   background: var(--id-c-yellow-signal);
  //   color: var(--id-c-white);
  // }
}

footer {
  margin-top: var(--spacing-120);
  border-top: 1px solid var(--id-c-grey-20);
  padding-top: var(--spacing-16);
  padding-bottom: var(--spacing-16);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media all and (max-width: $breakSmall) {
    margin-top: var(--spacing-80);
  }
}

.footer-credits {
  a {
    @media all and (max-width: $breakSmall) {
      display: block;
      // &:after {
      //   display: none;
      // }
    }
  }
}

.footer-bottom {
  margin-top: var(--spacing-40);

  >.columns {
    align-items: flex-end;
  }
}

.footer-illustration {
  @media all and (max-width: $breakSmall) {
    margin-top: 40px;
  }

  img {
    height: 260px;
    width: auto;

    @media all and (max-width: $breakSmall) {
      height: 195px;
    }
  }
}

.with-icon {
  margin-right: var(--spacing-16);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    margin-left: 28px;
    font-size: 24px;
    height: 24px;
    color: var(--id-c-grey-80);

    @media all and (max-width: $breakSmall) {
      margin-bottom: 2px;
      font-size: 16px;
      height: 16px;
    }
  }
}

.work-detail-title {
  color: var(--id-c-green-100);
}

p+p {
  margin-top: 1em;
}

.text-row .col ul:not([class]),
.text-row .col ol:not([class]) {
  margin-left: 20px;
}

.text-row .col ul:not([class]) {
  list-style: disc;
}

.text-row .col ol:not([class]) {
  list-style: decimal;
}

.flow>*+* {
  margin-block-start: var(--flow-space, 1em);
}

.work-detail-intro,
.journal-detail-intro {
  padding-bottom: var(--spacing-40);
}

.journal-detail-intro {
  padding-top: var(--spacing-60);

  @media all and (max-width: $breakSmall) {
    padding-top: var(--spacing-40);
  }
}

.work-detail-meta {
  margin-bottom: var(--spacing-40);

  @media all and (max-width: $breakSmall) {
    margin-bottom: var(--spacing-20);
  }

  &:last-child {
    @media all and (max-width: $breakSmall) {
      margin-bottom: var(--spacing-40);
    }
  }
}

.work-detail-download {
  .file-size {
    text-transform: lowercase;
  }
}

.work-detail-designer-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media all and (max-width: $breakSmall) {
    flex-wrap: wrap;
  }

  .work-detail-designer {
    min-width: calc((100vw - (var(--gutter) * 5) - (var(--pageMarginTotal) * 2)) / 6);
    margin-right: var(--gutter);

    @media all and (max-width: $breakSmall) {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: var(--spacing-16);
      }
    }
  }
}

.work-detail-credits,
.work-detail-download,
.extended-team-list {

  a,
  span.non-link {
    display: inline-flex;
    min-width: calc((100vw - (var(--gutter) * 5) - (var(--pageMarginTotal) * 2)) / 6);
    margin-right: var(--gutter);

    @media all and (max-width: $breakSmall) {
      display: flex;
    }
  }

  *+a,
  *+span.non-link {
    @media all and (max-width: $breakSmall) {
      margin-top: var(--spacing-8);
    }
  }
}

.journal-detail-intro .work-detail-credits {

  *+a,
  *+span.non-link {
    @media all and (max-width: $breakSmall) {
      margin-top: 0;
    }
  }
}

.media-caption {
  margin-top: var(--spacing-8);
  margin-bottom: var(--spacing-40);
  @include type-detail;

  @media all and (max-width: $breakSmall) {
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-20);
  }
}

.content-row .media.no-caption {
  margin-bottom: var(--spacing-16);
}

.discover-more-projects {
  margin-top: var(--spacing-120);

  @media all and (max-width: $breakSmall) {
    margin-top: var(--spacing-80);
  }
}

.more-projects {
  margin-top: var(--spacing-40);
}

.projects-list-header {
  padding-bottom: var(--spacing-8);

  @media all and (max-width: $breakSmall) {
    display: none;
  }
}

.projects-list-header,
.projects-list-item {
  border-bottom: 1px solid var(--id-c-grey-20);
}

.col.border-bottom {
  padding-bottom: var(--spacing-12);

  &:after {
    content: "";
    display: block;
    width: calc(100% - var(--gutter));
    height: 1px;
    background: var(--id-c-grey-20);
    position: absolute;
    bottom: 0;
    left: calc(var(--gutter) / 2);
  }
}

.projects-list-item {
  display: block;
  color: var(--id-c-green-100);
  padding: var(--spacing-8) 0;
  transition: border-color 0.2s var(--easing);

  &:nth-child(2) {
    @media all and (max-width: $breakSmall) {
      border-top: 1px solid var(--id-c-grey-20);
    }
  }

  >.columns {
    align-items: center;
  }

  .designer-tags {
    margin-top: 0;

    @media all and (max-width: $breakSmall) {
      margin-top: var(--spacing-8);
      margin-bottom: var(--spacing-8);
    }
  }

  &:hover {
    border-bottom-color: var(--id-c-yellow-signal);
  }
}

.people-section {
  margin-top: var(--spacing-120);

  .type-heading {
    margin-bottom: 5px;
  }

  .people {
    margin-top: var(--spacing-4);

    .person-image {
      margin-bottom: var(--spacing-16);

      @media all and (max-width: $breakSmall) {
        margin-bottom: var(--spacing-12);
      }
    }

    span.name,
    span.role {
      color: var(--id-c-green-100);
    }

    .person {
      margin-bottom: var(--spacing-80);

      .designer-tags {
        @media all and (max-width: $breakSmall) {
          margin-bottom: var(--spacing-40);
        }
      }
    }

    &.reverse {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.person-resume {
  margin-top: var(--spacing-80);
  border-top: 1px solid var(--id-c-grey-20);
  padding-top: var(--spacing-16);
  margin-bottom: var(--spacing-40);

  @media all and (max-width: $breakSmall) {
    margin-top: var(--spacing-40);
    padding-top: var(--spacing-8);
  }
}

.resume-list {
  @media all and (max-width: $breakSmall) {
    margin-top: var(--spacing-40);
  }
}

.resume-list-row {
  display: flex;
  align-items: center;
  gap: var(--gutter);

  @media all and (max-width: $breakSmall) {
    flex-wrap: wrap;
    gap: 0;
    margin-bottom: var(--spacing-8);
  }

  p+p {
    margin-top: 0;
  }

  .year,
  .role,
  .link {
    width: 33.33%;
  }

  .year {
    color: var(--id-c-green-100);

    @media all and (max-width: $breakSmall) {
      width: 37.5%;
    }
  }

  .link,
  .role {
    @media all and (max-width: $breakSmall) {
      width: 62.5%;
    }
  }

  .role {
    @media all and (max-width: $breakSmall) {
      margin-left: 37.5%;
    }
  }

  .link {
    a {
      display: flex;
    }
  }
}

.person-socials {
  margin-bottom: var(--spacing-40);

  a {
    display: block;
  }
}

.person-press {
  a {
    min-width: calc((100vw - (var(--gutter) * 5) - (var(--pageMarginTotal) * 2)) / 6);

    @media all and (max-width: $breakSmall) {
      display: flex;
      margin-right: 0;
    }
  }
}

a.min-width-link {
  min-width: calc((100vw - (var(--gutter) * 5) - (var(--pageMarginTotal) * 2)) / 6);
}

.inline-row {
  display: inline-flex;
  gap: 0.2em;

  @media all and (max-width: $breakSmall) {
    display: flex;
  }
}

.team-image {
  display: block;
  width: calc(100% + var(--pageMarginTotal));
  max-width: none;
}

hr {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--id-c-grey-20);
  margin-bottom: var(--spacing-16);
}

.team-section {
  margin-bottom: var(--spacing-120);

  @media all and (max-width: $breakSmall) {
    margin-bottom: var(--spacing-80);
  }
}

.information-section {
  margin-bottom: var(--spacing-120);

  @media all and (max-width: $breakSmall) {
    margin-bottom: var(--spacing-80);
  }
}

.information-row,
.expanding-row {
  padding: var(--spacing-16) 0 var(--spacing-40) 0;
  position: relative;

  @media all and (max-width: $breakSmall) {
    padding: var(--spacing-8) 0 var(--spacing-12) 0;
  }

  &:after {
    content: "";
    display: block;
    width: calc(100% - (var(--pageMarginTotal) * 2));
    height: 1px;
    background: var(--id-c-grey-20);
    position: absolute;
    bottom: 0;
    left: var(--pageMarginTotal);
  }
}

.expanding-row {
  transition: padding 0.2s ease;
  padding: 0;

  .expanding-row-header {
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    padding: var(--spacing-16) 0 var(--spacing-40) 0;

    @media all and (max-width: $breakSmall) {
      padding: var(--spacing-8) 0 var(--spacing-12) 0;
    }

    &:hover {
      color: var(--id-c-grey-60);
    }
  }

  .expanding-icon {
    position: absolute;
    right: var(--pageMarginTotal);
    top: var(--spacing-16);
    font-size: 24px;
    height: 24px;

    @media all and (max-width: $breakSmall) {
      font-size: 16px;
      height: 16px;
      top: var(--spacing-12);
    }

    .icon-minus {
      display: none;
    }
  }

  .expanded-content {
    display: none;

    @media all and (max-width: $breakSmall) {
      margin-top: 4px;
    }

    >.columns {
      opacity: 0;
    }
  }

  &.open {
    padding-bottom: var(--spacing-16);

    @media all and (max-width: $breakSmall) {
      padding-bottom: var(--spacing-8);
    }

    .expanding-icon {
      .icon-plus {
        display: none;
      }

      .icon-minus {
        display: inline-block;
      }
    }

    .expanded-content {
      .columns {
        animation: fadeIn 0.2s 0.2s forwards linear;
      }
    }
  }
}

.clients-list {
  margin-top: var(--spacing-40);
  margin-bottom: var(--spacing-40);
  @include type-heading;

  @media all and (max-width: $breakSmall) {
    margin-bottom: calc(var(--spacing-40) - var(--spacing-12));
  }

  li {
    color: var(--id-c-green-100);
  }
}

.information-row p,
.team-section p,
.person p,
.text-row p,
.expanding-row p {
  max-width: 600px;
}

.extended-team-list {
  p {
    max-width: 100%;
  }
}