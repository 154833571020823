.columns {
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--pageMargin);
  width: 100%;

  .columns {
    padding: 0;
    width: calc(100% + var(--gutter));
    margin-left: calc((var(--gutter) / 2) * -1);
  }
}

.col {
  padding: 0 calc(var(--gutter) / 2);
  position: relative;
}

.col-1 {
  width: 8.333%;
}
.col-2 {
  width: 16.666%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.333%;
}
.col-5 {
  width: 41.666%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.333%;
}
.col-8 {
  width: 66.666%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.333%;
}
.col-11 {
  width: 91.666%;
}
.col-12 {
  width: 100%;
}

.push-1 {
  margin-left: 8.333%;
}
.push-2 {
  margin-left: 16.666%;
}
.push-3 {
  margin-left: 25%;
}
.push-4 {
  margin-left: 33.333%;
}
.push-5 {
  margin-left: 41.666%;
}
.push-6 {
  margin-left: 50%;
}
.push-7 {
  margin-left: 58.333%;
}
.push-8 {
  margin-left: 66.666%;
}
.push-9 {
  margin-left: 75%;
}
.push-10 {
  margin-left: 83.333%;
}
.push-11 {
  margin-left: 91.666%;
}

.tablet-lndscp-push-0 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 0;
  }
}
.tablet-lndscp-push-1 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 8.333%;
  }
}
.tablet-lndscp-push-2 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 16.666%;
  }
}
.tablet-lndscp-push-3 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 25%;
  }
}
.tablet-lndscp-push-4 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 33.333%;
  }
}
.tablet-lndscp-push-5 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 41.666%;
  }
}
.tablet-lndscp-push-6 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 50%;
  }
}
.tablet-lndscp-push-7 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 58.333%;
  }
}
.tablet-lndscp-push-8 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 66.666%;
  }
}
.tablet-lndscp-push-9 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 75%;
  }
}
.tablet-lndscp-push-10 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 83.333%;
  }
}
.tablet-lndscp-push-11 {
  @media all and (max-width: $breakTabletLndscp) {
    margin-left: 91.666%;
  }
}

.tablet-lndscp-col-1 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 8.333%;
  }
}
.tablet-lndscp-col-2 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 16.666%;
  }
}
.tablet-lndscp-col-3 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 25%;
  }
}
.tablet-lndscp-col-4 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 33.333%;
  }
}
.tablet-lndscp-col-5 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 41.666%;
  }
}
.tablet-lndscp-col-6 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 50%;
  }
}
.tablet-lndscp-col-7 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 58.333%;
  }
}
.tablet-lndscp-col-8 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 66.666%;
  }
}
.tablet-lndscp-col-9 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 75%;
  }
}
.tablet-lndscp-col-10 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 83.333%;
  }
}
.tablet-lndscp-col-11 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 91.666%;
  }
}
.tablet-lndscp-col-12 {
  @media all and (max-width: $breakTabletLndscp) {
    width: 100%;
  }
}

.tablet-push-0 {
  @media all and (max-width: $breakTablet) {
    margin-left: 0;
  }
}
.tablet-push-1 {
  @media all and (max-width: $breakTablet) {
    margin-left: 8.333%;
  }
}
.tablet-push-2 {
  @media all and (max-width: $breakTablet) {
    margin-left: 16.666%;
  }
}
.tablet-push-3 {
  @media all and (max-width: $breakTablet) {
    margin-left: 25%;
  }
}
.tablet-push-4 {
  @media all and (max-width: $breakTablet) {
    margin-left: 33.333%;
  }
}
.tablet-push-5 {
  @media all and (max-width: $breakTablet) {
    margin-left: 41.666%;
  }
}
.tablet-push-6 {
  @media all and (max-width: $breakTablet) {
    margin-left: 50%;
  }
}
.tablet-push-7 {
  @media all and (max-width: $breakTablet) {
    margin-left: 58.333%;
  }
}
.tablet-push-8 {
  @media all and (max-width: $breakTablet) {
    margin-left: 66.666%;
  }
}
.tablet-push-9 {
  @media all and (max-width: $breakTablet) {
    margin-left: 75%;
  }
}
.tablet-push-10 {
  @media all and (max-width: $breakTablet) {
    margin-left: 83.333%;
  }
}
.tablet-push-11 {
  @media all and (max-width: $breakTablet) {
    margin-left: 91.666%;
  }
}

.tablet-col-1 {
  @media all and (max-width: $breakTablet) {
    width: 8.333%;
  }
}
.tablet-col-2 {
  @media all and (max-width: $breakTablet) {
    width: 16.666%;
  }
}
.tablet-col-3 {
  @media all and (max-width: $breakTablet) {
    width: 25%;
  }
}
.tablet-col-4 {
  @media all and (max-width: $breakTablet) {
    width: 33.333%;
  }
}
.tablet-col-5 {
  @media all and (max-width: $breakTablet) {
    width: 41.666%;
  }
}
.tablet-col-6 {
  @media all and (max-width: $breakTablet) {
    width: 50%;
  }
}
.tablet-col-7 {
  @media all and (max-width: $breakTablet) {
    width: 58.333%;
  }
}
.tablet-col-8 {
  @media all and (max-width: $breakTablet) {
    width: 66.666%;
  }
}
.tablet-col-9 {
  @media all and (max-width: $breakTablet) {
    width: 75%;
  }
}
.tablet-col-10 {
  @media all and (max-width: $breakTablet) {
    width: 83.333%;
  }
}
.tablet-col-11 {
  @media all and (max-width: $breakTablet) {
    width: 91.666%;
  }
}
.tablet-col-12 {
  @media all and (max-width: $breakTablet) {
    width: 100%;
  }
}

.push-1,
.push-2,
.push-3,
.push-4,
.push-5,
.push-6,
.push-7,
.push-8,
.push-9,
.push-10,
.push-11,
.tablet-lndscp-push-0,
.tablet-lndscp-push-1,
.tablet-lndscp-push-2,
.tablet-lndscp-push-3,
.tablet-lndscp-push-4,
.tablet-lndscp-push-5,
.tablet-lndscp-push-6,
.tablet-lndscp-push-7,
.tablet-lndscp-push-8,
.tablet-lndscp-push-9,
.tablet-lndscp-push-10,
.tablet-lndscp-push-11,
.tablet-push-0,
.tablet-push-1,
.tablet-push-2,
.tablet-push-3,
.tablet-push-4,
.tablet-push-5,
.tablet-push-6,
.tablet-push-7,
.tablet-push-8,
.tablet-push-9,
.tablet-push-10,
.tablet-push-11 {
  @media screen and (max-width: $breakSmall) {
    margin-left: 0;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.tablet-lndscp-col-1,
.tablet-lndscp-col-2,
.tablet-lndscp-col-3,
.tablet-lndscp-col-4,
.tablet-lndscp-col-5,
.tablet-lndscp-col-6,
.tablet-lndscp-col-7,
.tablet-lndscp-col-8,
.tablet-lndscp-col-9,
.tablet-lndscp-col-10,
.tablet-lndscp-col-11,
.tablet-lndscp-col-12,
.tablet-col-1,
.tablet-col-2,
.tablet-col-3,
.tablet-col-4,
.tablet-col-5,
.tablet-col-6,
.tablet-col-7,
.tablet-col-8,
.tablet-col-9,
.tablet-col-10,
.tablet-col-11,
.tablet-col-12 {
  @media screen and (max-width: $breakSmall) {
    width: 100%;
  }
}

.mobile-col-1 {
  @media all and (max-width: $breakSmall) {
    width: 12.5%;
  }
}
.mobile-col-2 {
  @media all and (max-width: $breakSmall) {
    width: 25%;
  }
}
.mobile-col-3 {
  @media all and (max-width: $breakSmall) {
    width: 37.5%;
  }
}
.mobile-col-4 {
  @media all and (max-width: $breakSmall) {
    width: 50%;
  }
}
.mobile-col-5 {
  @media all and (max-width: $breakSmall) {
    width: 62.5%;
  }
}
.mobile-col-6 {
  @media all and (max-width: $breakSmall) {
    width: 75%;
  }
}
.mobile-col-7 {
  @media all and (max-width: $breakSmall) {
    width: 87.5%;
  }
}
.mobile-col-8 {
  @media all and (max-width: $breakSmall) {
    width: 100%;
  }
}

.mobile-push-0 {
  @media all and (max-width: $breakSmall) {
    margin-left: 0;
  }
}
.mobile-push-1 {
  @media all and (max-width: $breakSmall) {
    margin-left: 12.5%;
  }
}
.mobile-push-2 {
  @media all and (max-width: $breakSmall) {
    margin-left: 25%;
  }
}
.mobile-push-3 {
  @media all and (max-width: $breakSmall) {
    margin-left: 37.5%;
  }
}
.mobile-push-4 {
  @media all and (max-width: $breakSmall) {
    margin-left: 50%;
  }
}
.mobile-push-5 {
  @media all and (max-width: $breakSmall) {
    margin-left: 62.5%;
  }
}
.mobile-push-6 {
  @media all and (max-width: $breakSmall) {
    margin-left: 75%;
  }
}
.mobile-push-7 {
  @media all and (max-width: $breakSmall) {
    margin-left: 87.5%;
  }
}

.show-tablet {
  display: none;

  @media all and (max-width: $breakTablet) {
    display: flex;
  }
}

.hide-tablet {
  @media all and (max-width: $breakTablet) {
    display: none;
  }
}

.show-small {
  display: none;

  @media all and (max-width: $breakSmall) {
    display: flex;
  }
}

.hide-small {
  @media all and (max-width: $breakSmall) {
    display: none;
  }
}
